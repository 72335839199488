export const QUOTES = [
  {
    speaker: "Conrad Dreyer",
    occupation: "Dairy Farmer",
    quote:
      "I have a long-standing relationship going back 10 years with Red Cap Energy.  They understand that farming is my core business and are always willing to assist when I need them to solve issues that may arise from time to time.  The wind turbines are a great addition to farm revenues and have had a negligible impact on the day to day operation of my dairy farm.”",
    imagePath: "/landowners/img-landowners-quote-2.png",
  },
  {
    speaker: "Oloff Cilliers",
    occupation: "Dairy Farmer",
    quote:
      "Red Cap Energy has always treated us with respect, looking out for our interests and keeping to their word.  Having wind turbines on my farm has given greater financial security and has allowed us to expand our operations with the extra revenues.”",
    imagePath: "/landowners/img-landowners-quote-1.png",
  },
]
