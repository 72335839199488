import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { Button, Cover, Quotes, Section } from "../components"
import { QUOTES } from "../components/utils"

const Landowners = () => {
  return (
    <Wrapper>
      <Cover className="banner">
        <div className="textContainer shadowContainer">
          <div className="content">
            <h1>Landowners</h1>
            <p className="font-style-3">
              There are many benefits to landowners harnessing the wind for
              energy production – it enables farmers to diversify the use of
              their land and provides more financial security to landowners.
            </p>
          </div>
        </div>
        <div className="imageContainer">
          <StaticImage
            src="../images/landowners/img-landowners-cover.png"
            alt=""
          />
        </div>
      </Cover>
      <Section className="benefits">
        <div>
          <StaticImage
            src="../images/landowners/img-landowners-benefits.png"
            alt=""
          />
        </div>
        <div className="textContainer">
          <div className="text">
            <h2>Benefits To Landowners</h2>
            <p>
              Landowners are able to receive a reliable income generation source
              by leasing their land for wind farms, and are able to continue
              with their everyday farming activities. The financial returns
              depend on the number of turbines on the land, the project size,
              the wind speed, and the proximity to the grid and the bid tariff
              agreed with Eskom.
            </p>
          </div>
          <Button
            label="Get In Touch"
            onClick={() => {
              navigate("/contact")
            }}
          />
        </div>
      </Section>
      <Section className="process">
        <div className="textContainer">
          <h2>Development Process</h2>
          <p className="font-style-2">
            <span className="value">4-7</span>
            <span className="unit">Yrs</span>
          </p>
          <p>
            This involves land identification, permitting, environmental impact
            assessment studies (EIA), bidding for government and the actual
            construction of the wind farm.
          </p>
        </div>
        <div className="imageContainer">
          <StaticImage
            src="../images/landowners/img-landowners-process.png"
            alt=""
          />
        </div>
      </Section>
      <Section className="operation">
        <div>
          <StaticImage
            src="../images/landowners/img-landowners-operation.png"
            alt=""
          />
        </div>
        <div className="textContainer">
          <div>
            <h2>{`Operation & Maintenance`}</h2>
            <p>
              The independent power producer (IPP) has a 20 year Power Purchase
              Purchase Agreement with Eskom and pays the farmer a monthly income
              according the number of turbines on their land.
            </p>
          </div>
        </div>
      </Section>
      <Quotes quotes={QUOTES} />
    </Wrapper>
  )
}

export default Landowners

const Wrapper = styled.div`
  .banner {
    padding-bottom: 90px;
  }

  .benefits,
  .process,
  .operation {
    display: grid;
    padding-top: 70px;

    .textContainer {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        opacity: 0.8;
      }
    }
  }

  .benefits {
    grid-template-columns: 3fr 4fr;

    .textContainer {
      max-width: 605px;

      p {
        margin: 20px 0 40px 0;
      }
    }
  }

  .process {
    grid-template-columns: minmax(40%, 500px) minmax(60%, 810px);

    &.contentContainer {
      padding-right: 0;
    }

    .textContainer {
      margin-left: 10px;
      max-width: 365px;

      .font-style-2 {
        margin: 20px 0 0 0;
        opacity: 1;
      }

      .value {
        font-family: "Gilroy";
        font-size: 140px;
        line-height: 90px;
        margin-right: 20px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .operation {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 70px;

    .textContainer {
      max-width: 390px;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    .banner {
      padding-bottom: 0;
    }

    .process {
      .textContainer {
        margin-right: 50px;

        .font-style-2 {
          margin: 0;
        }

        .value {
          font-size: 120px;
        }
      }

      .imageContainer {
        align-items: center;
        display: flex;
      }
    }

    .benefits,
    .operation {
      .textContainer {
        margin-left: 50px;
      }
    }
  }

  @media screen and (min-width: ${(props) =>
      props.theme.minBreakpoints.xl}) and (max-width: ${(props) =>
      props.theme.breakpoints.xxl}) {
    .banner .imageContainer {
      padding-bottom: 2vw;
      padding-top: 4.5vw;
      max-width: 720px;
      width: 48%;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.minBreakpoints.xl}) {
    .banner {
      .textContainer {
        padding-bottom: 140px;

        .content .font-style-3 {
          font-weight: 400;
        }
      }
    }

    .benefits,
    .operation {
      padding: 130px 130px 0 130px;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    .benefits,
    .process,
    .operation {
      grid-template-columns: 1fr;
      row-gap: 30px;

      .textContainer {
        max-width: 100%;
      }
    }

    .benefits,
    .operation {
      .textContainer {
        margin-left: 0;
      }
    }

    .process {
      .textContainer {
        margin-left: 0;
      }
    }

    .operation {
      .textContainer {
        grid-row: 1;
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    .process {
      .textContainer .value {
        font-size: 98px;
        margin-right: 14px;
      }
    }
  }
`
